import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { logIn, setSelectedCompanyRutFromExternalLogin } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { GET_USER } from '@fingo/lib/graphql';
import { Typography, CircularProgress } from '@mui/material';

const ManagermasRegisterForm = ({
  companyRut,
  companyDomain,
  companyId,
  token,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { refetch } = useQuery(GET_USER, {
    onCompleted: (_data) => {
      if (_data && _data.getUser) {
        history.push(
          { pathname: '/app' },
          { partner: 'manager' },
        );
      }
    },
  });

  const loginRequest = (params) => {
    const body = { ...params };
    body.button_flag = params.button;
    delete body.button;
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
  };

  const doManagermasLogin = useCallback(async (loginParams) => {
    const requestParams = loginRequest(loginParams);
    // eslint-disable-next-line no-undef
    const authResponse = await fetch(`${process.env.REACT_APP_HOST}managermas_auth`, requestParams);
    const res = await authResponse.json();
    return res;
  }, []);

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    const asyncLogin = async () => {
      try {
        const response = await doManagermasLogin({
          company_rut: companyRut,
          company_domain: companyDomain,
          company_id: companyId,
          token,
        });
        logIn(response);
        setSelectedCompanyRutFromExternalLogin(response);
        refetch();
      } catch (err) {
        handleError(err);
      }
    };
    asyncLogin();
  }, [companyDomain, companyId, companyRut, token, refetch, doManagermasLogin]);

  return (
    <>
      {(loading) && (
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={40} />
        </div>
      )}
      {error && (
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Typography variant="h3" align="center" gutterBottom color="primary">¡Fallamos! :(</Typography>
          <Typography variant="h6" align="center">No logramos hacer que te conectaras a Fingo, por favor intenta más tarde</Typography>
        </div>
      )}
    </>
  );
};

ManagermasRegisterForm.propTypes = {
  companyRut: PropTypes.string.isRequired,
  companyDomain: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default ManagermasRegisterForm;
