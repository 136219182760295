import React from 'react';
import { Stack, Typography } from '@mui/material';
import NoteAlt from '@mui/icons-material/NoteAlt';

const KobrosAddDataForCollection = () => (
  <Stack
    spacing={2}
    alignItems="center"
    justifyContent="center"
  >
    <NoteAlt sx={{ fontSize: 60, color: 'kobros.main' }} />
    <Typography textAlign="center" variant="h1" marginBottom={1}>
      Agregar {' '}
      <Typography variant="h1" sx={{ color: 'kobros.main' }} component="span">
        Información de Recaudación
      </Typography>
    </Typography>
    <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold' }}>
      ¡Con koBros puedes guardar los datos de pago de tus facturas!
    </Typography>
    <Typography textAlign="center">
      Ingresa la fecha y método de pago indicada por tu cliente,
      para que puedas revisar la fecha real de pago indicada por tu cliente.
    </Typography>
  </Stack>
);

export default KobrosAddDataForCollection;
