import React from 'react';
import { Typography, Box } from '@mui/material';

const ChurnJoinFingoText = () => (
  <Box
    textAlign="center"
    sx={{
      margin: { xs: 2, sm: 4, md: 6, lg: 10 },
      '& .bold-text': {
        fontWeight: 'bold',
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
        lineHeight: '1.3',
      },
      '& .highlight-text': {
        color: 'primary.main',
        fontWeight: 'bold',
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
      },
    }}
  >
    <Typography variant="h4" className="bold-text">
      Únete hoy a las +1000 empresas
    </Typography>
    <Typography variant="h4" className="bold-text">
      que{' '}
      <Typography variant="h4" component="span" className="highlight-text">
        confían en fingo
      </Typography>
    </Typography>
  </Box>
);

export default ChurnJoinFingoText;
