import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Divider, InputAdornment, Paper, Stack, Typography } from '@mui/material';
import { FullHeightBox } from '@fingo/lib/components/boxes';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { REGISTER_USER } from '@fingo/lib/graphql';
import { Wave } from '@fingo/lib/assets';
import { formatRut, getFormFieldError } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { PasswordStrength } from '../utils';
import { useLogin } from '../../hooks';
import { isPasswordValid } from '../utils/passwordCheckers';
import GoogleSign from '../oauth/GoogleSign';

const RegisterTextField = styled(ValidationTextFieldInput)(() => ({
  width: '100%',
}));

const RegisterForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
  });
  const [loginGql, loadingLogin, errorForm] = useLogin(
    formData.email.value.toLowerCase(),
    formData.password.value,
  );
  const cookies = new Cookies();
  const translateRegisterError = (error) => {
    if (error.message === 'user already exist') {
      const emailMessage = 'Ese email ya tiene una cuenta. Intenta con otro o inicia sesión.';
      setFormData((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          error: emailMessage,
        },
      }));
      return emailMessage;
    }
    return 'Hubo un error al crear tu cuenta. Intenta más tarde.';
  };
  const [registerError, setRegisterError] = useState('');
  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: formData.email.value.toLowerCase(),
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      password: formData.password.value,
      phoneNumber: `${t('Phone prefix')}${formData.phoneNumber.value}`,
      utmCampaign: cookies.get('utm_campaign') || null,
    },
    onError: (error) => {
      setRegisterError(translateRegisterError(error));
    },
    onCompleted: loginGql,
  });
  const handleChange = (e) => {
    let { value } = e.target;
    const { name, type } = e.target;
    if (name === 'companyRut') value = formatRut(value);
    const fieldError = getFormFieldError(value, type, name);
    if (fieldError.isError) {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: fieldError.message, value },
      }));
    } else {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: '', value },
      }));
      if (name === 'email') {
        setRegisterError('');
      }
    }
  };
  const isButtonReady = () => {
    for (let i = 0; i < Object.values(formData).length; i += 1) {
      const element = Object.values(formData)[i];
      if (element.value === '' || element.error !== '') {
        return false;
      }
    }
    if (!isPasswordValid(formData.password.value)) {
      return false;
    }
    return true;
  };
  const setEndAdornment = ({ element }) => {
    if (element.value !== '') {
      if (element.error === '') {
        return <InputAdornment position="end"><Check color="success" /></InputAdornment>;
      }
      return <InputAdornment position="end"><Close color="error" /></InputAdornment>;
    }
    return null;
  };
  return (
    <FullHeightBox
      sx={{
        backgroundImage: `url(${Wave})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
      }}
    >
      <Typography
        variant="h2"
        align="center"
        color="primary.main"
        sx={{
          fontSize: { xs: '25px !important', md: '25px !important' },
          mb: 2,
        }}
      >
        Regístrate
      </Typography>
      <Paper
        sx={{
          width: { xs: 250, sm: 400 },
          py: 4,
          px: { xs: 4, md: 8 },
          mr: 'auto',
          ml: 'auto',
        }}
        elevation={4}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            registerUser();
          }}
          id="loginForm"
        >
          <Stack alignItems="center" spacing={1.5} width="100%">
            <RegisterTextField
              name="firstName"
              validationtype="text"
              type="text"
              label="Nombre"
              variant="standard"
              value={formData.firstName.value}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  setEndAdornment({ element: formData.firstName })
                ),
              }}
            />
            <RegisterTextField
              name="lastName"
              validationtype="text"
              type="text"
              label="Apellido"
              variant="standard"
              value={formData.lastName.value}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  setEndAdornment({ element: formData.lastName })
                ),
              }}
            />
            <RegisterTextField
              name="phoneNumber"
              validationtype="phone"
              type="number"
              label="Número de teléfono"
              variant="standard"
              value={formData.phoneNumber.value}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">+{t('Phone prefix')}</InputAdornment>,
                endAdornment: (
                  setEndAdornment({ element: formData.phoneNumber })
                ),
              }}
            />
            <RegisterTextField
              name="email"
              validationtype="email"
              type="email"
              label="Email"
              variant="standard"
              value={formData.email.value}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  setEndAdornment({ element: formData.email })
                ),
              }}
            />
            <PasswordInput
              name="password"
              label="Contraseña"
              variant="standard"
              value={formData.password.value}
              onChange={handleChange}
              error={!!errorForm?.password}
              helperText={errorForm.password}
              sx={{
                width: '100%',
              }}
            />
            <PasswordStrength password={formData.password.value} />
            {registerError && (
            <Typography
              fontWeight={500}
              color="error"
            >
              {registerError}
            </Typography>
            )}
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              loading={loadingLogin || loadingRegisterUser}
              disabled={!isButtonReady()}
              id="register-create-account"
              fullWidth
            >
              Registrarme con Fingo
            </LoadingButton>
            <Divider sx={{
              width: '100%',
            }}
            >
              ó
            </Divider>
            <GoogleSign label="Registrarme con Google" />
          </Stack>
        </form>
      </Paper>
    </FullHeightBox>
  );
};

export default RegisterForm;
