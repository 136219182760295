import Cookies from 'universal-cookie';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

const UtmCampaignTracker = ({ children }) => {
  const cookies = new Cookies();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign') || '';
    cookies.set('utm_campaign', utmCampaign, { path: '/' });
  }, []);
  return (
    <>
      {children}
    </>
  );
};

UtmCampaignTracker.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UtmCampaignTracker;
